import { HistoryFiltersData } from '../sections/history/filters/HistoryFilters.utils'

export type State = {
  historyFilters: HistoryFiltersData
}

export const initialState: State = {
  historyFilters: {
    order: 'newest',
    states: [],
    page: 1,
    size: 50
  }
}
