import type { CookieSerializeOptions } from 'cookie'

export interface CookieType {
  key: string
  options: CookieSerializeOptions
}

export function defaultCookies(
  useSecureCookies: boolean
): Record<'csrf' | 'session' | 'expiry' | 'impersonification', CookieType> {
  const prefix = useSecureCookies ? '__Secure-' : ''

  return {
    csrf: {
      key: `${useSecureCookies ? '__Host-' : ''}auth.csrf`,
      options: {
        httpOnly: true,
        sameSite: 'lax',
        path: '/',
        secure: useSecureCookies
      }
    },
    impersonification: {
      key: `${prefix}auth.impersonification`,
      options: {
        httpOnly: false,
        sameSite: 'lax',
        path: '/',
        secure: useSecureCookies
      }
    },
    session: {
      key: `${prefix}auth.session`,
      options: {
        httpOnly: true,
        sameSite: 'lax',
        path: '/',
        secure: useSecureCookies
      }
    },
    expiry: {
      key: `${prefix}auth.expiry`,
      options: {
        httpOnly: false,
        sameSite: 'lax',
        path: '/',
        secure: useSecureCookies
      }
    }
  }
}
