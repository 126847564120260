import { createContext, ReactNode, useContext, useReducer } from 'react'
import { auctionReducer, Dispatch } from './reducer'
import { initialState, State } from './state'

type AuctionProviderProps = { children: ReactNode }

const AuctionContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

export function AuctionProvider({ children }: AuctionProviderProps) {
  const [state, dispatch] = useReducer(auctionReducer, initialState)

  const value = { state, dispatch }

  return (
    <AuctionContext.Provider value={value}>{children}</AuctionContext.Provider>
  )
}

export function useAuctionContext() {
  const context = useContext(AuctionContext)

  if (context === undefined) {
    throw new Error('No context was propagated')
  }

  return context
}
