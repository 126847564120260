import { captureException } from '@sentry/nextjs'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { localStorageClient } from 'driverama-core/utils/dom'
import { Maybe } from 'driverama-core/utils/types'
import { AuthPublic, getSession } from './auth'
import { addSearchParams, SearchParams } from './searchParams'

export type FetchArgs = Omit<RequestInit, 'body' | 'method'> & {
  body?: Record<string, unknown> | FormData | string
  toBlob?: boolean
  method?: HTTPMethod
  searchParams?: SearchParams
}

export interface FetchResponse {
  status: number
  headers?: Headers
}

export interface FetchJsonResponse<T> extends FetchResponse {
  json?: T
}

export interface FetchBlobResponse extends FetchResponse {
  blob?: Blob
}

export interface Fetcher {
  (path: string, args: FetchArgs & { toBlob: true }): Promise<FetchBlobResponse>
  <Json>(path: string, args?: FetchArgs & { toBlob?: false }): Promise<
    FetchJsonResponse<Json>
  >
}

export class FetcherError extends Error {
  statusCode: number

  constructor(
    public readonly path: string,
    public readonly response: FetchJsonResponse<{
      code?: string
      message?: string[]
    }>
  ) {
    super(FetcherError.transformErrorMessage(response))
    this.statusCode = response.status
  }

  public static transformErrorMessage(
    response: FetchJsonResponse<{ code?: string; message?: string[] }>
  ) {
    const { message, code } = response.json || {}
    return `FetchError[${code || 0}]: ${
      Array.isArray(message) ? message.join(', ') : message
    }`
  }
}

function getSavedLocale() {
  return localStorageClient.getItem('locale')?.toLowerCase()
}

// https://docs.mapbox.com/api/search/geocoding/#reverse-geocoding
// Options are IETF language tags comprised of a mandatory ISO 639-1 language code and, optionally, one or more IETF subtags for country or script.
// TODO: move to other file
export function getMapboxLocale(): string {
  const savedLocale = getSavedLocale()
  switch (savedLocale) {
    default:
    case 'en':
      return 'en'
    case 'de':
      return 'de'
    case 'nl':
      return 'nl'
  }
}

export function getApiLocale(): string {
  const savedLocale = getSavedLocale()
  switch (savedLocale) {
    default:
    case 'en':
      return 'ENU'
    case 'de':
      return 'DEU'
    case 'nl':
      return 'NLD'
  }
}

export function getApiRegion(): string {
  const savedBusinessCountry = localStorageClient
    .getItem('businessCountry')
    ?.toLowerCase()

  if (savedBusinessCountry && ['de', 'nl'].includes(savedBusinessCountry)) {
    return savedBusinessCountry
  }

  switch (process.env.NEXT_PUBLIC_REGION) {
    case 'nl':
      return 'nl'
    case 'de':
    default:
      return 'de'
  }
}
/**
 * Must match the following
 *
 * application/json
 * application/json; charset=utf-8
 * application/com.driverama-v1+json
 * application/com.driverama-v3+json
 * application/com.driverama-v1+json; charset=utf-8
 */
const IS_JSON_REGEXP = /application\/json|application\/com\.driverama-v\d+\+json/

export function createFetcher(
  baseUrl: string,
  getSession?: () => Promise<AuthPublic | null>
): Fetcher {
  async function fetchCall<T = unknown | Blob>(
    path: string,
    fetchArgs?: FetchArgs
  ): Promise<FetchJsonResponse<T> | FetchBlobResponse> {
    const body = fetchArgs?.body
    const method = fetchArgs?.method || HTTPMethod.GET
    const url = addSearchParams(`${baseUrl}${path}`, fetchArgs?.searchParams)

    const options: RequestInit = {
      ...fetchArgs,
      body: undefined,
      headers: {
        Accept: 'application/com.driverama-v1+json',
        'Accept-Language': getApiLocale(),
        'Driverama-Business-Country': getApiRegion(),
        ...(global.FormData != null && body instanceof FormData
          ? undefined
          : { 'Content-Type': 'application/json' }),
        'X-Install-UUID': `${process.env.NEXT_PUBLIC_X_INSTALL_UUID}`,
        'X-Platform': 'WEB',
        ...(fetchArgs?.headers || {})
      }
    }

    if (getSession) {
      const session = await getSession()
      const token = session?.access_token

      if (token) {
        options.headers = {
          ...options.headers,
          ...getAuthorizationHeader(token)
        }
      } else {
        const from =
          typeof window !== 'undefined' ? window.location.href : 'server'
        captureException(
          new Error('Unable to fetch without session'),
          scope => {
            scope.setContext('fetch', {
              url,
              from
            })

            return scope
          }
        )
      }
    }

    const canIncludeBody = ![HTTPMethod.GET, HTTPMethod.HEAD].includes(method)
    if (canIncludeBody) {
      if (global.FormData != null && body instanceof FormData) {
        options.body = body
      } else {
        options.body =
          body !== Object(body) ? String(body) : JSON.stringify(body)
      }
    }

    try {
      const result = await fetch(url, options)

      const contentType = result.headers?.get('Content-Type') || ''
      const isJSON = IS_JSON_REGEXP.test(contentType)

      if (!result.ok) {
        throw new FetcherError(path, {
          status: result.status,
          headers: result.headers,
          json: isJSON ? await result.json() : null
        })
      }

      if (fetchArgs?.toBlob) {
        const response: FetchBlobResponse = {
          status: result.status,
          headers: result.headers,
          blob: await result.blob()
        }

        return response
      } else {
        const response: FetchJsonResponse<T> = {
          status: result.status,
          headers: result.headers,
          json: isJSON ? await result.json() : null
        }

        return response
      }
    } catch (error) {
      captureException(error)
      throw error
    }
  }

  return fetchCall
}

export function getAuthorizationHeader(token: Maybe<string>) {
  return token ? { Authorization: `Bearer ${token}` } : undefined
}

export function createAcceptHeaderWithVersion(version = 1) {
  return `application/com.driverama-v${version}+json`
}

export const localFetcher = createFetcher('')

export const apiFetcher = createFetcher(
  process.env.NEXT_PUBLIC_API_URL as string
)

export const apiAuthFetcher = createFetcher(
  process.env.NEXT_PUBLIC_API_URL as string,
  getSession
)
