interface Listener<Public> {
  (value: Public): void
}

export interface Store<Public> {
  session: Public | null
}

export class PublicDataStore<Public> {
  private readonly key = '_authStore'
  readonly empty = { session: null }

  private data: Store<Public> = this.empty
  private callbacks: Listener<Store<Public>>[] = []

  constructor() {
    if (typeof window !== 'undefined') {
      this.update(undefined, { stopPropagation: true })

      window.addEventListener('storage', event => {
        if (event.key === this.key) {
          this.update(undefined, { stopPropagation: true })
        }
      })
    }
  }

  private emit(value: Store<Public>) {
    this.callbacks.forEach(fn => fn(value))
  }

  subscribe(listener: Listener<Store<Public>>) {
    this.callbacks = this.callbacks.concat(listener)

    return () => {
      this.callbacks = this.callbacks.filter(fn => fn !== listener)
    }
  }

  clear() {
    this.update(this.empty)
  }

  get session() {
    return this.data.session
  }

  // undefined: triggers revalidation
  update(value?: Store<Public>, options?: { stopPropagation: boolean }) {
    if (!options?.stopPropagation) {
      localStorage.setItem(this.key, Date.now().toString())
    }

    this.data = value ?? this.empty
    this.emit(this.data)
  }
}
