import { captureException } from '@sentry/nextjs'
import { defaultCookies } from 'driverama-core/auth/cookie'
import { PublicDataStore } from 'driverama-core/auth/utils/store'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useEffect, useState } from 'react'
import { getApiRegion } from '../utils/fetcher'
class AuthResponseError extends Error {
  constructor(public readonly statusCode: number) {
    super('AUTH:fetchSession | Invalid response during retrieving token')
  }
}

export class SessionError extends Error {
  constructor() {
    super('Unable to retrieve session')
  }
}
export interface AuthSecret {
  refresh_token?: string
  token_type?: string
  expires?: number
  refresh_expires?: number
  token_id?: string
}

export interface AuthPublic {
  access_token?: string
  refresh_token?: string
  token_type?: string
  expires: number
  token_id?: string
}

async function getIsAuthenticated() {
  const session = await getSession()
  return !!session?.access_token
}

interface UseSessionState {
  session: AuthPublic | null
  loading: boolean
}

// TODO: handle expiry timeout in useSession
const storage = new PublicDataStore<AuthPublic>()
const CACHE_HIT_RANGE = 10 * 1000
const authCookies = defaultCookies(false)

function useSession() {
  const [session, setSession] = useState<UseSessionState>({
    ...storage.empty,
    loading: true
  })

  useEffect(() => {
    async function retrieveSession() {
      if (document.cookie.includes(authCookies.expiry.key)) {
        try {
          const session = await fetchSession()
          // NOTE: there are problems with storage.subscribe in cypress, this makes sure that useSession works as expected every time
          setSession({ session, loading: false })
        } catch (err) {
          setSession({ session: null, loading: false })
          console.error(err)
          captureException(err)
        }
      } else {
        setSession({ session: storage.session, loading: false })
      }
    }

    retrieveSession()
    return storage.subscribe(data => setSession({ ...data, loading: false }))
  }, [])

  return session
}

async function getCsrfToken(): Promise<string> {
  const res = await fetch('/api/auth/csrf').then(a => a.json())

  if (typeof res?.csrf !== 'string') throw new Error('Invalid CSRF type')
  return res.csrf
}

const fetchSession = (() => {
  async function retrieve(): Promise<AuthPublic> {
    const csrf = await getCsrfToken()
    const region = getApiRegion()
    const res = await fetch(`/api/auth/token?_csrf=${csrf}`, {
      method: HTTPMethod.GET,
      headers: {
        'Content-Type': 'application/json',
        'Driverama-Business-Country': region
      }
    })

    if (!res.ok) {
      throw new AuthResponseError(res.status)
    }
    const json: AuthPublic = await res.json()

    // TODO: validate the response
    storage.update({ session: json })

    if (!storage.session)
      throw new Error(
        'AUTH:fetchSession | Could not retrieve session from the storage'
      )
    return storage.session
  }

  let cache: ReturnType<typeof retrieve>
  let cacheStamp = -Infinity

  return function getter() {
    const now = performance.now()

    if (now - cacheStamp >= CACHE_HIT_RANGE) {
      cache = retrieve()
      cacheStamp = now
    }

    return cache
  }
})()

async function login(payload: AuthSecret): Promise<AuthPublic> {
  const csrf = await getCsrfToken()
  const region = getApiRegion()

  const res = await fetch(`/api/auth/token?_csrf=${csrf}`, {
    method: HTTPMethod.PUT,
    headers: {
      'Content-Type': 'application/json',
      'Driverama-Business-Country': region
    },
    body: JSON.stringify(payload)
  })

  if (!res.ok) throw new Error('AUTH:login | Invalid response during login')
  const json: AuthPublic = await res.json()

  // TODO: validate the response
  storage.update({ session: json })

  const session = storage.session
  if (!session)
    throw new Error('AUTH:login | Could not retrieve session from the storage')
  return session
}

async function logout(): Promise<void> {
  const csrf = await getCsrfToken()
  const region = getApiRegion()

  const res = await fetch(`/api/auth/token?_csrf=${csrf}`, {
    method: HTTPMethod.DELETE,
    headers: {
      'Driverama-Business-Country': region
    }
  })

  if (!res.ok) throw new Error('AUTH:logout | Invalid response during logout')
  storage.clear()
}

async function getSession(): Promise<AuthPublic | null> {
  let session: AuthPublic | null = storage.session

  if (
    (storage.session?.expires ?? 0) <= Date.now() &&
    document.cookie.includes(authCookies.expiry.key)
  ) {
    session = await fetchSession()
  }

  return session
}

export {
  login,
  logout,
  getSession,
  useSession,
  getIsAuthenticated,
  authCookies
}
