import {
  formatDate,
  formatEur,
  formatEurDecimals,
  formatHours,
  formatKm,
  formatNum
} from 'driverama-core/utils/formatting'
import fs from 'fs/promises'
import i18next, { Resource } from 'i18next'
import getConfig from 'next/config'
import path from 'path'
import { initReactI18next } from 'react-i18next'

import { captureException } from '@sentry/nextjs'
import { de, nl } from 'date-fns/locale'
import { getRemoteTranslation } from 'driverama-core/api/weblate/translations'
import { localStorageClient } from 'driverama-core/utils/dom'

export interface IntlProps {
  i18n: { lng: string; resources: Resource }
}

export function getLocalePackage(lang: string) {
  switch (lang) {
    case 'de':
      return de
    case 'nl':
      return nl
    default:
      return undefined
  }
}

export function createIntlClient(props: IntlProps['i18n'] | undefined) {
  if (!props) {
    throw new Error('Missing translation props')
  }

  const inst = i18next.createInstance()

  inst.on('languageChanged', locale => {
    localStorageClient.setItem('locale', locale)
  })

  inst.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: props.lng,
    resources: props.resources,
    defaultNS: 'core',
    react: {
      transKeepBasicHtmlNodesFor: ['br'],
      transSupportBasicHtmlNodes: true
    },
    interpolation: {
      format(value, format, lng) {
        if (format === 'formatNum') {
          return formatNum(value, undefined, lng)
        }

        if (format === 'formatEur') {
          return formatEur(value, lng)
        }

        if (format === 'formatEurDecimals') {
          return formatEurDecimals(value, lng)
        }

        if (format === 'formatKm') {
          return formatKm(value, lng)
        }

        if (format === 'formatHours' && typeof value === 'number') {
          return formatHours(value, getLocalePackage(lng || props.lng))
        }

        if (value instanceof Date) {
          return formatDate(
            value,
            format || '',
            getLocalePackage(lng || props.lng)
          )
        }

        return value
      },
      escapeValue: false // not needed for react as it escapes by default
    }
  })

  return inst
}

// eslint-disable-next-line @typescript-eslint/ban-types
type PreviewData = string | object | null | boolean | undefined

function isWeblatePreviewContext(ctx: PreviewData): ctx is { weblate: string } {
  if (ctx && typeof ctx === 'object' && 'weblate' in ctx) {
    return true
  }

  return false
}

export function getProjectIntlProps<Resources extends string>(
  project: string,
  nsPrefix?: string
) {
  return async (
    context: {
      locale?: string | undefined
      preview?: boolean
      previewData?: PreviewData
    },
    loadNamespaces: Resources[]
  ): Promise<IntlProps> => {
    const { serverRuntimeConfig } = getConfig()
    const lng = context.locale || serverRuntimeConfig.defaultLocale || 'en'
    const resources: IntlProps['i18n']['resources'] = {
      [lng]: {}
    }

    const namespaces = ['core', ...loadNamespaces]

    for (const ns of namespaces) {
      if (!resources[lng]) resources[lng] = {}

      const weblateToken = isWeblatePreviewContext(context.previewData)
        ? context.previewData.weblate
        : null

      if (context.preview && weblateToken) {
        const res = await getRemoteTranslation(
          project,
          nsPrefix ?? project,
          lng,
          ns,
          weblateToken
        )
        resources[lng][ns] = res.json ?? {}
      } else {
        const filePath = path.join(process.cwd(), 'src/lang', lng, `${ns}.json`)
        try {
          const file = await fs.readFile(filePath, { encoding: 'utf-8' })
          resources[lng][ns] = JSON.parse(file)
        } catch {
          const error = new Error(`Error reading file: ${filePath}`)
          captureException(error)
        }
      }
    }

    return { i18n: { lng, resources } }
  }
}

export { i18next }
