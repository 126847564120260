import type { NextComponentType, NextPageContext } from 'next'
import type { AppProps as NextAppProps } from 'next/app'
import Head from 'next/head'
import { defaultCookies } from './cookie'

const HIDE_BODY_CSS = `
  body::before {
    content: '';
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: #fff;
  }
  
  .render body::before {
    display: none;
  }
`

export enum AuthRedirectType {
  RequiresAuth,
  RequiresNoAuth,
  None
}

export type NextPageWithAuth<
  TProps,
  TInitialProps = unknown
> = NextComponentType<NextPageContext, TInitialProps, TProps> & {
  requiresAuth?: boolean
  auth?: {
    type?: AuthRedirectType
    destination?: string | ((props: unknown) => string)
  }
}

export interface AuthAppProps<Props = unknown> extends NextAppProps<Props> {
  Component: NextPageWithAuth<Props>
}

function getAuthRedirectScript(args: {
  type: AuthRedirectType
  destination: string
  cookies: ReturnType<typeof defaultCookies>
}) {
  const cookieName = args.cookies.expiry.key
  switch (args.type) {
    case AuthRedirectType.RequiresAuth:
      return `
        if (!document.cookie || document.cookie.indexOf('${cookieName}') === -1) {
          location.replace('${args.destination}?next=' + encodeURIComponent(location.pathname + location.search));
        } else {
          document.documentElement.classList.add('render');
        }
      `
    case AuthRedirectType.RequiresNoAuth:
      return `
        if (!document.cookie || document.cookie.indexOf('${cookieName}') === -1) {
          document.documentElement.classList.add('render');
        } else if ('${args.destination}') {
          location.replace('${args.destination}' + location.search);
        }
      `
    case AuthRedirectType.None:
    default:
      return undefined
  }
}

export function AuthRedirectHead(props: {
  type: AuthRedirectType
  destination: string
  cookies: ReturnType<typeof defaultCookies>
}) {
  const script = getAuthRedirectScript(props)
  if (!script) {
    return null
  }

  return (
    <Head>
      <script dangerouslySetInnerHTML={{ __html: script }} />
      <style dangerouslySetInnerHTML={{ __html: HIDE_BODY_CSS }} />
    </Head>
  )
}
