import { HTTPMethod } from 'driverama-core/constants/rest'
import { createFetcher } from 'driverama-core/utils/fetcher'

const weblateFetcher = createFetcher(process.env.NEXT_PUBLIC_WEBLATE as string)

export function getRemoteWeblateConfig(token: string) {
  return weblateFetcher('/api', {
    method: HTTPMethod.GET,
    headers: { Accept: '*/*', Authorization: `Bearer ${token}` }
  })
}

export function getRemoteTranslation(
  project: string,
  nsPrefix: string,
  locale: string,
  ns: string,
  token: string
) {
  return weblateFetcher<Record<string, string>>(
    `/api/translations/${project}/${nsPrefix}-${ns}/${locale}/file`,
    { headers: { Accept: '*/*', Authorization: `Bearer ${token}` } }
  )
}
