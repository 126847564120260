import { initialState, State } from './state'
import { HistoryFiltersData } from '../sections/history/filters/HistoryFilters.utils'

type Action =
  | {
      type: 'SET_HISTORY_FILTERS'
      payload: Partial<HistoryFiltersData>
    }
  | {
      type: 'RESET_HISTORY_FILTERS'
    }

export type Dispatch = (action: Action) => void

export function auctionReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_HISTORY_FILTERS':
      return {
        ...state,
        historyFilters: {
          ...state.historyFilters,
          ...action.payload
        }
      }

    case 'RESET_HISTORY_FILTERS':
      return {
        ...state,
        historyFilters: initialState.historyFilters
      }

    default:
      return initialState
  }
}
