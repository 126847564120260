import type { AuthAppProps } from 'driverama-core/auth/next'
import { hasWindow } from 'driverama-core/utils/dom'
import { addSearchParams } from 'driverama-core/utils/searchParams'
import { GetServerSidePropsContext, GetStaticPropsContext } from 'next'
import qs from 'query-string'

export function resolveRedirectDestination(
  auth: AuthAppProps['Component']['auth'],
  routeParams?: Record<string, unknown>
): string | undefined {
  if (!auth) {
    return undefined
  }

  const { destination } = auth
  const resolvedDestination =
    typeof destination === 'function' ? destination(routeParams) : destination

  if (resolvedDestination && hasWindow()) {
    return addSearchParams(
      resolvedDestination,
      qs.parse(window.location.search)
    )
  }

  return resolvedDestination
}

export function getLocalePath(
  ctx: { defaultLocale?: string; locale?: string },
  path: string
) {
  if (ctx.locale && ctx.locale !== ctx.defaultLocale) {
    return `/${ctx.locale}${path}`
  }
  return path
}

export function extractParamFromPageContext(
  context: GetServerSidePropsContext | GetStaticPropsContext,
  paramName: string,
  index: number | 'last' = 0
): string {
  const query = context.params?.[paramName]
  if (!query) {
    throw new Error(
      `Parameter [${paramName}] not found. This should never happen.`
    )
  }

  if (Array.isArray(query)) {
    const queryItem = query[index === 'last' ? query.length - 1 : index]
    if (!queryItem) {
      throw new Error(
        `Parameter [${paramName}] not found. This should never happen.`
      )
    }

    return queryItem
  }

  return query
}
